/* Container */
.submarine-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  height: 150px;
  margin: 20px auto;
  z-index: 99999;
}

/* Submarine Styling */
.submarine {
  position: relative;
  width: 150px;
  height: 80px;
  background-color: orange;
  border-radius: 50px 50px 40px 40px;
  cursor: pointer;
  transition: transform 2s ease-out;
}

/* Submarine Body */
.submarine-body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: orange;
  border-radius: 50px 50px 40px 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Submarine Windows */
.submarine-window {
  width: 30px;
  height: 30px;
  background-color: lightblue;
  border-radius: 50%;
  border: 2px solid yellow;
}

/* Submarine Propeller */
.submarine-propeller {
  position: absolute;
  left: -15px;
  top: 25px;
  width: 30px;
  height: 30px;
  background-color: lightblue;
  border-radius: 50%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Submarine Periscope */
.submarine-periscope {
  position: absolute;
  top: -20px;
  right: 20px;
  width: 10px;
  height: 30px;
  background-color: orange;
  border-radius: 10px;
  box-shadow: 0 -15px 0 orange;
}

/* Bubbles Animation */
.bubbles {
  position: absolute;
  left: -30px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bubble {
  width: 10px;
  height: 10px;
  background-color: lightblue;
  border-radius: 50%;
  animation: pop 2s infinite;
  opacity: 0;
}

.bubble:nth-child(1) {
  animation-delay: 0.2s;
}

.bubble:nth-child(2) {
  animation-delay: 0.4s;
}

.bubble:nth-child(3) {
  animation-delay: 0.6s;
}

/* Bubble Pop Animation */
@keyframes pop {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

/* Launch Animation */
.submarine-launch {
  transform: translateX(300px); /* Moves to the right */
}