h1 {
  opacity: 0;
  animation: fadeIn 3s forwards;
  z-index: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.rocket-button {
  margin-bottom: 15px;
  opacity: 0;
  animation: fadeIn 10s forwards;
  z-index: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.submarine-button {
  opacity: 0;
  animation: fadeIn 10s forwards;
  z-index: 1;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}