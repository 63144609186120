/* ContactForm.css */
.content-container {
  width: 750px;
  overflow-y: auto;
  max-height: 2000px;
  z-index: 2;
}

.content-container {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* Adjust colors as needed */
}

/* Hide scrollbar for Webkit (Chrome, Safari) */
.content-container::-webkit-scrollbar {
  width: 0; /* Adjust width to hide completely */
}

/* Optional: Add a transparent background to the scrollbar track */
.content-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.post-it-note {
  width: 300px; /* Fixed width, adjust as needed */
  max-width: 100%; /* Responsive width */
  padding: 20px; /* Padding for text */
  margin: 20px; /* Margin around the note */
  background-color: #fffb88; /* Yellow sticky note color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* Shadow for 3D effect */
              0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #e8e8e8; /* Subtle border */
  font-family: 'Comic Sans MS', cursive, sans-serif; /* Fun font style */
  color: #333; /* Text color */
  line-height: 1.5; /* Line height for readability */
  position: relative; /* For pseudo-elements */
  transform: rotate(-2deg); /* Slight rotation for a natural look */
}

/* Adding a triangle effect at the top left corner */
.post-it-note::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fffb88 #fffb88; /* Matches note color */
  box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
}

/* Adding a tape effect */
.post-it-note::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: rotate(-10deg);
}

.contact-me-form {
  z-index: 1;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.form-group {
  margin-bottom: 10px;
}

label {
  display: block;
  margin-bottom: 5px;

}

.button-container {
   margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.hundred-button {
  width: calc(100% - 40px); /* 100% width with 20px margin on both sides */
  margin: 20px; /* Margin to separate from other buttons */
}

.fifty-button {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px; /* Adjust margin as needed */
}

.fifty-button a {
  width: calc(50% - 10px); /* 50% width with 5px margin on both sides */
}

input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

/* Modal styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.space-heading {
  margin-top: 0;
  text-align: center;
}

.modal-content {
  background-color: #fefefe;
  color: black;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: left;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-content h3 {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content a {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007BFF;
  text-decoration: none;
  border-radius: 5px;
}

.modal-content button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #15a0c6;
  text-decoration: none;
  border-radius: 5px;
}

.modal-content a:hover {
  background-color: #0056b3;
}

.close {
  color: #aaa;
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: block;
  line-height: 24px;
}

.close:hover {
  color: #000;
}